import React, { lazy } from "react"
import ReactDOM from "react-dom/client"
import "./index.scss"

import { createBrowserRouter, RouterProvider } from "react-router-dom"

// what was already there
export const HomePage = lazy(() => import("./pages/Home"))
export const RegistrationPage = lazy(() => import("./pages/Registration"))
export const ErrorPage = lazy(() => import("./pages/Error"))

const router = createBrowserRouter([
	{
		path: "/",
		element: <HomePage />,
		errorElement: <ErrorPage />,
	},
	{
		path: "/register",
		element: <RegistrationPage />,
		errorElement: <ErrorPage />,
	},
])

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
    <React.StrictMode>
        <RouterProvider router={router} />
    </React.StrictMode>
)
